import Login from './Authorization/Login';
import SignOut from './Authorization/SignOut';
import Activation from './Authorization/Activation';
import PasswordRecovery from './Authorization/PasswordRecovery';
import Verification from './Authorization/Verification';
import ResendCode from './Authorization/ResendCode';
import Locked from './Authorization/Locked';

import PlanManager from './AdminPortal/PlanManager';
import PlanManagerDetails from './AdminPortal/PlanManagerDetails';
import CarrierManager from './AdminPortal/CarrierManager';
import CarrierManagerDetails from './AdminPortal/CarrierManagerDetails';
import CarrierManagerCreateDetails from './AdminPortal/CarrierManagerCreateDetails';
import TimelineTemplates from './AdminPortal/TimelineTemplates';
import TimelineTemplatesCreation from './AdminPortal/TimelineTemplatesCreation';
import TimelineTemplatesDetails from './AdminPortal/TimelineTemplatesDetails';
import AppConfiguration from './AdminPortal/AppConfiguration';
import AppConfigurationDetails from './AdminPortal/AppConfigurationDetails';
import RecommendationsManager from './AdminPortal/RecommendationsManager';
import RecommendationCardCreation from './AdminPortal/RecommendationCardCreation';
import RecommendationCardDetails from './AdminPortal/RecommendationCardDetails';
import EligibilityImports from './AdminPortal/EligibilityImports';
import IndividualGroupConfiguration from './AdminPortal/IndividualGroupConfiguration';
import IndividualGroupConfigurationArchived from './AdminPortal/IndividualGroupConfigurationArchived';
import IndividualValidationReport from './AdminPortal/IndividualValidationReport';
import IndividualValidationReportThresholds from './AdminPortal/IndividualValidationReportThresholds';
import IndividualValidationReportBlockerErrorsDetails from './AdminPortal/IndividualValidationReportBlockerErrorsDetails';
import IndividualVerificationReport from './AdminPortal/IndividualVerificationReport';
import IndividualVerificationReportDetailedCorePlans from './AdminPortal/IndividualVerificationReportDetailedCorePlans';
import IndividualVerificationReportCardLinks from './AdminPortal/IndividualVerificationReportCardLinks';
import AllClients from './AdminPortal/AllClients';
import TPAPartner from './AdminPortal/TPAPartner';
import TPAPartnerConfiguration from './AdminPortal/TPAPartnerConfiguration';
import TPAPartnerConfigurationArchived from './AdminPortal/TPAPartnerConfigurationArchived';
import TPAGroupDetails from './AdminPortal/TPAGroupDetails';
import TPAGroupsAll from './AdminPortal/TPAGroupsAll';
import TPAAutoimportGroupConfiguration from './AdminPortal/TPAAutoimportGroupConfiguration';
import TPAGroupBlockerErrorsReport from './AdminPortal/TPAGroupBlockerErrorsReport';
import TPAGroupBlockerErrorsReportDetails from './AdminPortal/TPAGroupBlockerErrorsReportDetails';
import PreprocessingReport from './AdminPortal/PreprocessingReport';
import PreprocessingReportError from './AdminPortal/PreprocessingReportError';
import ConfigureTPAGroups from './AdminPortal/ConfigureTPAGroups';
import TPAValidationReport from './AdminPortal/TPAValidationReport';
import TPAValidationReportThresholds from './AdminPortal/TPAValidationReportThresholds';
import TPAVerificationReport from './AdminPortal/TPAVerificationReport';
import TPAVerificationReportDetailedCorePlans from './AdminPortal/TPAVerificationReportDetailedCorePlans';
import TPAVerificationReportCardLinks from './AdminPortal/TPAVerificationReportCardLinks';
import IndividualGroup from './AdminPortal/IndividualGroup';
import PendingTerminationsMembers from './AdminPortal/PendingTerminationsMembers';
import PendingMigrationsMembers from './AdminPortal/PendingMigrationsMembers';
import ImportBlockerErrorsReport from './AdminPortal/ImportBlockerErrorsReport';
import ImportBlockerErrorsReportDetails from './AdminPortal/ImportBlockerErrorsReportDetails';

import Timeline from './Home/Timeline';
import TimelineGeneration from './Home/TimelineGeneration';
import CompanyInfo from './ContactsAndCompany/CompanyInfo';
import ContactInfo from './ContactsAndCompany/ContactInfo';
import BaseContactInfo from './ContactsAndCompany/BaseContactInfo';
import Contacts from './ContactsAndCompany/Contacts';
import CoreBenefits from './Benefits/Core';
import AncillaryBenefits from './Benefits/Ancillary';
import AncillaryBenefitsDetails from './Benefits/AncillaryDetails';
import CoreBenefitsDetails from './Benefits/CoreDetails';
import BenefitsPreview from './Benefits/Preview';
import EligibilityOverview from './Eligibility/Overview';
import EligibilityDetails from './Eligibility/Details';
import EligibilityExport from './Eligibility/Export';
import BenefitsOverview from './Overview/Benefits';
import OpenEnrollment from './Overview/OpenEnrollment';

import BookOfBusiness from './Reporting/BookOfBusiness';
import CostContainment from './Reporting/CostContainment';
import BenefitsUsage from './Reporting/BenefitsUsage';
import CompleteRoi from './Reporting/CompleteRoi';
import MemberActivation from './Reporting/MemberActivation';
import DownloadReports from './Reporting/DownloadReports';

import BroadcasterWelcome from './Broadcaster/Welcome';
import BroadcastEditor from './Broadcaster/BroadcastEditor';
import BroadcastDetails from './Broadcaster/BroadcastDetails';

import PageNotFound from './Error/PageNotFound';
import ServerError from './Error/ServerError';

import IESunset from './Warning/IESunset';
import ZendeskRedirection from './Technical/ZendeskRedirection';

import EducationAndMarketing from './EducationAndMarketing';

import PdfTemplate from './PdfTemplate';

export default {
    Login,
    SignOut,
    Activation,
    PasswordRecovery,
    Verification,
    ResendCode,
    Locked,

    PlanManager,
    PlanManagerDetails,
    CarrierManager,
    CarrierManagerDetails,
    CarrierManagerCreateDetails,
    TimelineTemplates,
    TimelineTemplatesCreation,
    TimelineTemplatesDetails,
    AppConfiguration,
    AppConfigurationDetails,
    RecommendationsManager,
    RecommendationCardCreation,
    RecommendationCardDetails,
    EligibilityImports,
    IndividualGroupConfiguration,
    IndividualGroupConfigurationArchived,
    IndividualValidationReport,
    IndividualValidationReportThresholds,
    IndividualValidationReportBlockerErrorsDetails,
    IndividualVerificationReport,
    IndividualVerificationReportDetailedCorePlans,
    IndividualVerificationReportCardLinks,
    AllClients,
    TPAPartner,
    TPAPartnerConfiguration,
    TPAPartnerConfigurationArchived,
    TPAGroupDetails,
    TPAGroupsAll,
    TPAAutoimportGroupConfiguration,
    TPAGroupBlockerErrorsReport,
    TPAGroupBlockerErrorsReportDetails,
    PreprocessingReport,
    PreprocessingReportError,
    ConfigureTPAGroups,
    IndividualGroup,
    PendingTerminationsMembers,
    PendingMigrationsMembers,
    ImportBlockerErrorsReport,
    ImportBlockerErrorsReportDetails,
    TPAValidationReport,
    TPAValidationReportThresholds,
    TPAVerificationReport,
    TPAVerificationReportDetailedCorePlans,
    TPAVerificationReportCardLinks,

    Timeline,
    TimelineGeneration,
    CompanyInfo,
    ContactInfo,
    BaseContactInfo,
    Contacts,
    CoreBenefits,
    AncillaryBenefits,
    AncillaryBenefitsDetails,
    CoreBenefitsDetails,
    BenefitsPreview,
    EligibilityOverview,
    EligibilityDetails,
    EligibilityExport,
    BenefitsOverview,
    OpenEnrollment,

    BookOfBusiness,
    CostContainment,
    BenefitsUsage,
    CompleteRoi,
    MemberActivation,
    DownloadReports,

    BroadcasterWelcome,
    BroadcastEditor,
    BroadcastDetails,

    PageNotFound,
    ServerError,

    IESunset,
    ZendeskRedirection,

    EducationAndMarketing,

    PdfTemplate
};
