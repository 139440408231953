import React, {useCallback, useMemo, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import Table, {ROW_HIGHLIGHT_TYPES} from '@frontend/ui-kit/Components/Table';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import MultipleChoice from '../MultipleChoice';
import {
    setMultipleChoiceValues,
    clearMultipleChoice,
    requestPendingTerminations,
    requestPendingMigrations,
    requestPendingGroupUpdating
} from '../../../actions/adminPortal';
import {requestGroups} from '../../../actions/general';
import {convertDateToTimeZone, getUTCDate, equal, formatDate, isEmpty, promisifyAsyncFunction} from '../../../utils';
import {ELIGIBILITY_IMPORT_TABS_VALUES, ROUTES} from '../../../constants';
import './index.scss';

const TABLE_PAGE_SIZE = 20;

const MULTIPLE_CHOICE_NAME = 'pendingThresholdsGroups';

const PendingThresholdsGroups = () => {
    const {hash} = useLocation();
    const navigate = useNavigate();
    const isPendingTerminations = useMemo(() => equal(hash.substring(1), ELIGIBILITY_IMPORT_TABS_VALUES.pending_terminations), [hash]);
    const [table, setTable] = useState({unitCount: 0, pageIndex: 0});
    const [tableData, setTableData] = useState([]);
    const rowProps = useMemo(() => {
        return tableData.reduce((acc, {pending_termination_visited: pendingTerminationVisited, pending_migration_visited: pendingMigrationVisited, company_alias: alias}) => {
            const isCompanyVisited = isPendingTerminations ? pendingTerminationVisited : pendingMigrationVisited;

            return {...acc, ...!isCompanyVisited ? {[alias]: {highlightType: ROW_HIGHLIGHT_TYPES.success}} : {}};
        }, {});
    }, [tableData, isPendingTerminations]);
    const [search, setSearch] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(search)) {
            setSearch({});
        }

        dispatch(setMultipleChoiceValues({}, MULTIPLE_CHOICE_NAME));
    }, [isPendingTerminations]);

    useEffect(() => {
        return () => {
            dispatch(clearMultipleChoice());
        };
    }, []);

    const onFetchData = useCallback(async ({pageIndex = 0, pageSize = TABLE_PAGE_SIZE, ...search}) => {
        const query = {
            limit: pageSize,
            offset: pageIndex * pageSize,
            ...search
        };
        const requestFunc = isPendingTerminations ? requestPendingTerminations : requestPendingMigrations;
        const {pendingTerminations, pendingMigrations, count, isSuccess} = await dispatch(requestFunc(query));

        if (!isSuccess) {
            return false;
        }

        setTable({unitCount: count, pageIndex});
        setTableData(isPendingTerminations ? pendingTerminations : pendingMigrations);
    }, [isPendingTerminations]);

    const onFetchTableData = useCallback(tableData => {
        onFetchData({...tableData, ...search});
    }, [search, isPendingTerminations]);

    const loadCompanyOptions = promisifyAsyncFunction(async (query, value) => {
        const {groups} = await dispatch(requestGroups({query}));

        return groups.map(group => ({label: group[value], value: group[value]}));
    });

    const filteredChoices = [
        {name: 'Company', type: 'company_name', loadOptions: query => loadCompanyOptions(query, 'title')},
        {name: 'Alias', type: 'company_alias', loadOptions: query => loadCompanyOptions(query, 'alias')}
    ];

    const getTableColumns = () => {
        const getDate = ({value: date}) => date && `${formatDate(convertDateToTimeZone(getUTCDate(date)), 'MM/dd/yyyy H:mm')} CST`;

        const getAction = ({cell}) => {
            const {company_alias: companyAlias, company_name: companyName, pending_termination_visited: pendingTerminationVisited, pending_migration_visited: pendingMigrationVisited} = cell?.row?.original || {};

            const onGoToPendingMembers = () => {
                const isGroupVisited = isPendingTerminations ? pendingTerminationVisited : pendingMigrationVisited;

                if (!isGroupVisited) {
                    const pendingKey = isPendingTerminations ? 'pending_termination_visited' : 'pending_migration_visited';
                    dispatch(requestPendingGroupUpdating({companyAlias, [pendingKey]: true}));
                }

                const routePath = isPendingTerminations ? 'pending_terminations' : 'pending_migrations';
                navigate(`${ROUTES.importsIndividual}/${companyAlias}/${routePath}?group_name=${encodeURIComponent(companyName)}`);
            };

            return <Button data-testid='action-button' onClick={onGoToPendingMembers} type={BUTTON_TYPES.tertiary}>View</Button>;
        };

        const getCompany = ({value, cell}) => {
            const {pending_termination_visited: pendingTerminationVisited, pending_migration_visited: pendingMigrationVisited} = cell?.row?.original || {};
            const isCompanyVisited = isPendingTerminations ? pendingTerminationVisited : pendingMigrationVisited;

            return <div>{!isCompanyVisited && <Icon type={ICON_TYPES.circle} className='pending-member-not-visited mr-2'/>} {value}</div>;
        };

        return [
            {Header: 'Date', accessor: isPendingTerminations ? 'pending_termination_last_change_date' : 'pending_migration_last_change_date', Cell: getDate},
            {Header: 'Company', accessor: 'company_name', Cell: getCompany},
            {Header: 'Alias', accessor: 'company_alias'},
            {Header: '# of Members', accessor: isPendingTerminations ? 'pending_termination_members_count' : 'pending_migration_members_count', isSortable: false, width: 170},
            {Header: 'Notes', accessor: `${isPendingTerminations ? 'pending_termination_notes' : 'pending_migration_notes'}.message`},
            {Header: 'Action', isSortable: false, Cell: getAction}
        ];
    };

    const tableProps = {
        data: tableData,
        pageSize: TABLE_PAGE_SIZE,
        isSortable: true,
        isFilterable: false,
        columns: getTableColumns(),
        filterPlaceholder: 'Search by name, email, or employee ID',
        className: 'mt-20',
        onFetchData: onFetchTableData,
        uniqueDataIdentifier: 'company_alias',
        rowProps,
        ...table
    };

    return (
        <React.Fragment>
            <MultipleChoice choices={filteredChoices} onChange={setSearch} name={MULTIPLE_CHOICE_NAME}/>

            <Table {...tableProps}/>
        </React.Fragment>
    );
};

export {PendingThresholdsGroups as TestablePendingThresholdsGroups};
export default PendingThresholdsGroups;
