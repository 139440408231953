import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Tweak from './Tweak';
import {FieldArray, Form} from '../../shared/FormComponents';
import {requestCompaniesByTPAPartner} from '../../../actions/general';
import {
    equal,
    filterUniqArrayOfObject,
    generateUniqueId,
    getItemKeyValue,
    isEmpty,
    isString,
    parseQuery,
    promisifyAsyncFunction
} from '../../../utils';
import {FORMS, IMPORT_CONFIG_TYPES, JSON_RPC_RESULTS, TPA_GROUP_TWEAK_JSON_FIELDS} from '../../../constants';
import {
    requestImportsJsonrpc,
    requestTPAGroupTweak,
    requestTPAGroupTweakUpdating,
    requestTPAPartnerImportSessions
} from '../../../actions/adminPortal';
import {getParsedImportConfigData, getStringifiedImportConfigData, getTPAGroupTweakValidation} from '../../../helpers';
import './index.scss';

const IMPORT_COMPLETE_STATUSES = ['failed', 'success', 'cancelled'];

const TWEAKS_NAME = 'import_configs_tweaks';

/* istanbul ignore next */
const validate = values => {
    return {
        import_configs_tweaks: values?.import_configs_tweaks?.map(getTPAGroupTweakValidation)
    };
};

const getFormattedSelectedGroups = search => {
    const selectedGroupsSearch = parseQuery(search).groups || [];
    return isString(selectedGroupsSearch) ? [selectedGroupsSearch] : selectedGroupsSearch;
};

const ConfigureTPAGroups = () => {
    const dispatch = useDispatch();
    const {search} = useLocation();
    const {partner} = useParams();
    const selectedGroups = getFormattedSelectedGroups(search);
    const [initialValues, setInitialValues] = useState({});
    const [groups, setGroups] = useState([]);
    const [importSession, setImportSession] = useState({});
    const [filterBy, setFilterBy] = useState({[IMPORT_CONFIG_TYPES.launch]: true, [IMPORT_CONFIG_TYPES.maintenance]: true});
    const navigate = useNavigate();

    const getGroupIsPending = (alias, importConfig = importSession) => {
        const {groups_individual_import_sessions: groupsIndividualImportSessions = []} = importConfig || {};

        return groupsIndividualImportSessions.some(group => {
            return equal(group?.company_data?.alias, alias) && !IMPORT_COMPLETE_STATUSES.includes(group.status);
        });
    };

    const parseTweak = value => getParsedImportConfigData(TPA_GROUP_TWEAK_JSON_FIELDS, {import_config: value}).import_config;

    const stringifyTweak = ({alias, ...tweak}) => ({
        ...getStringifiedImportConfigData(TPA_GROUP_TWEAK_JSON_FIELDS, {import_config: tweak}).import_config,
        alias
    });

    const onSearchChange = fields => async alias => {
        const {tweak, isSuccess} = await dispatch(requestTPAGroupTweak(alias));

        if (!isSuccess) {
            return false;
        }

        const isPending = getGroupIsPending(alias);
        const stringifiedTweak = stringifyTweak(tweak);
        const {id} = groups?.find(group => equal(group.alias, alias)) || {};

        fields.push({
            ...stringifiedTweak,
            company_id: id,
            alias,
            isPending
        });
    };

    const runTPAImport = async ({import_configs_tweaks: importConfigsTweaks = []}) => {
        const groupIdsToImport = importConfigsTweaks.map(getItemKeyValue('company_id'));
        const jsonrpcObj = {jsonrpc: '2.0', method: 'run_tpa_import', id: generateUniqueId(), params: {tpa_import_session_id: importSession.id, group_ids_to_import: groupIdsToImport}};
        const {jsonrpc, isSuccess} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

        return {
            jsonrpc,
            isSuccess: equal(jsonrpc?.result, JSON_RPC_RESULTS.success) && isSuccess
        };
    };

    const onSubmit = async ({is_submit: isSubmit, allGroupsInFile, ...values}) => {
        const enhancedTweaks = values.import_configs_tweaks.map(parseTweak);
        // FYI: This is a temporary solution. Will refactor it after adjusting API to have the possibility to work with multiple tweaks (Pasha, 01.17.2024)
        const tweaks = await Promise.all(enhancedTweaks.map(async ({alias, ...values}) => {
            const {tweak} = await dispatch(requestTPAGroupTweakUpdating(alias, values));

            return {alias, ...tweak};
        }));
        const stringifiedTweaks = tweaks.map(stringifyTweak);

        if (!isEmpty(stringifiedTweaks)) {
            const initialValues = stringifiedTweaks.map(groupTweak => {
                const {id} = groups?.find(group => equal(group.alias, groupTweak.alias)) || {};
                const isPending = getGroupIsPending(groupTweak.alias);

                return {
                    ...groupTweak,
                    company_id: id,
                    isPending
                };
            });

            setInitialValues({import_configs_tweaks: initialValues, allGroupsInFile});
        }

        if (isSubmit) {
            await runTPAImport(values);
            navigate(-1);
        }
    };

    const getTweak = (field, index) => {
        const tweakProps = {
            tweaksName: TWEAKS_NAME,
            groups,
            index,
            filterBy
        };

        return <Tweak {...tweakProps}/>;
    };

    const getInitialGroups = async () => {
        if (isEmpty(selectedGroups)) {
            return [];
        }

        const {groupsData: {data: groups}} = await dispatch(requestCompaniesByTPAPartner({partnerId: partner, aliasList: selectedGroups}));

        return groups;
    };

    const getImportSession = async () => {
        const {sessions} = await dispatch(requestTPAPartnerImportSessions({limit: 1, tpa_partner_id: partner, only_active: false}));

        return sessions.data?.[0] || {};
    };

    const getTweaks = async () => {
        // FYI: This is a temporary solution. Will refactor it after adjusting API to have the possibility to work with multiple tweaks (Pasha, 01.17.2024)
        const tweaks = await Promise.all(selectedGroups.map(async alias => {
            const {tweak} = await dispatch(requestTPAGroupTweak(alias));
            return {alias, ...tweak};
        }));

        return tweaks.map(stringifyTweak);
    };

    useEffect(() => {
        (async () => {
            const [
                initialGroupsData,
                sessionData,
                stringifiedTweaks
            ] = await Promise.all([
                getInitialGroups(),
                getImportSession(),
                getTweaks()
            ]);
            const {all_groups_in_file: allGroupsInFile} = sessionData?.preprocessing_report || {};

            const initialValues = selectedGroups.map(alias => {
                const {id} = initialGroupsData?.find(group => equal(group.alias, alias)) || {};
                const isPending = getGroupIsPending(alias, sessionData);
                const groupTweaks = stringifiedTweaks.find(tweakedGroup => equal(tweakedGroup.alias, alias)) || {};

                return {
                    ...groupTweaks,
                    company_id: id,
                    alias,
                    isPending
                };
            });

            setGroups(initialGroupsData);
            setImportSession(sessionData);
            setInitialValues({import_configs_tweaks: initialValues, allGroupsInFile});
        })();
    }, []);

    const setLoadedGroups = loadedGroups => groups => [...groups, ...loadedGroups].filter(filterUniqArrayOfObject('alias'));

    const loadCompanyAliasOptions = promisifyAsyncFunction(async (query, form) => {
        const {values} = form.getState();
        const {groupsData: {data}} = await dispatch(requestCompaniesByTPAPartner({alias: query, partnerId: partner, limit: 20}));
        const aliases = values.import_configs_tweaks.map(({alias}) => alias);
        const filteredGroups = data
            .filter(({alias}) => values.allGroupsInFile.includes(alias))
            .filter(({alias}) => !aliases.includes(alias));

        setGroups(setLoadedGroups(filteredGroups));
        return filteredGroups.map(({alias, title}) => ({label: title, value: alias}));
    });

    const onFilterChange = type => setFilterBy(state => ({...state, [type]: !state[type]}));

    return (
        <div className='configure-tpa-groups'>
            <Alert className='mb-20' type={ALERT_TYPES.warning} description='Renewals are not supported in the Admin Portal at this time. Please see Houston to configure and upload other remaining groups'/>

            <Heading className='mb-10' type={HEADING_TYPES['1']}>Configure TPA Groups</Heading>
            <Text className='mt-5'>Search and add specific groups from within this TPA to apply specific configurations where values may differ from the rest of the TPA partner groups. To add a group, search and select the group alias.</Text>

            <div className='mt-20 filters'>
                <Heading type={HEADING_TYPES['5']}>Filter by</Heading>
                <Sticker onClick={() => onFilterChange(IMPORT_CONFIG_TYPES.launch)} type={filterBy[IMPORT_CONFIG_TYPES.launch] ? STICKER_TYPES.primary : STICKER_TYPES.default} className='ml-8 filters__sticker'>🚀 Launch</Sticker>
                <Sticker onClick={() => onFilterChange(IMPORT_CONFIG_TYPES.maintenance)} type={filterBy[IMPORT_CONFIG_TYPES.maintenance] ? STICKER_TYPES.primary : STICKER_TYPES.default} className='ml-8 filters__sticker'>🛠️ Maintenance</Sticker>
            </div>

            <Form name={FORMS.configureTPAGroups} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({handleSubmit, form, values}) => {
                    const tweakAliases = values.import_configs_tweaks?.map(getItemKeyValue('alias')).sort() || [];
                    const isSearchAvailable = !values.allGroupsInFile?.sort().every((alias, index) => equal(alias, tweakAliases[index]));

                    const onSubmitClicked = () => {
                        form.change('is_submit', true);
                        handleSubmit();
                    };

                    const onSaveClicked = () => {
                        form.change('is_submit', false);
                        handleSubmit();
                    };

                    return (
                        <form noValidate data-testid='configure-tpa-groups'>
                            <FieldArray name={TWEAKS_NAME}>
                                {({fields}) => (
                                    <React.Fragment>
                                        {fields.map(getTweak)}

                                        {isSearchAvailable && (
                                            <AsyncAutocomplete className='mt-20'
                                                onChange={onSearchChange(fields)}
                                                loadOptions={query => loadCompanyAliasOptions(query, form)}
                                                filterConfig={{stringify: option => `${option.label} ${option.value}`}}
                                                isCreatable={false}/>
                                        )}

                                        <div className='configure-tpa-groups__action-bar mt-20'>
                                            <Button data-testid='save-tweaks-button' className='configure-tpa-groups__action-bar__button' onClick={onSaveClicked} type={BUTTON_TYPES.secondary}>Save Progress</Button>
                                            <Button data-testid='submit-tweaks-button' className='configure-tpa-groups__action-bar__button' onClick={onSubmitClicked} disabled={!fields.length}>Submit</Button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </FieldArray>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export {ConfigureTPAGroups as TestableConfigureTPAGroups};
export default React.memo(ConfigureTPAGroups);
