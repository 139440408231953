import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Table from '@frontend/ui-kit/Components/Table';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import MultipleChoice from '../MultipleChoice';
import withPopup from '../../../HOC/withPopup';
import {requestCoreCarriers} from '../../../actions/shared';
import {
    receiveCarrierManagerCarriers,
    requestCarrierManagerCarrierDeleting,
    requestCarrierManagerCarriers
} from '../../../actions/adminPortal';
import {getCarrierManagerCarriers, getMultipleChoiceValues} from '../../../selectors/adminPortal';
import {promisifyAsyncFunction} from '../../../utils';
import useSortByText from '../../../hooks/useSortByText';
import {ROUTES} from '../../../constants';
import {STATES_OPTIONS} from '../../../options';
import './index.scss';

const POPUP_ID = 'carrierManagerDeletionPopup';
const PER_PAGE_LIMIT = 50;

const CarrierManagerLayout = ({openPopup, closePopup}) => {
    const navigate = useNavigate();
    const [carriers, chosenValues] = useSelector(state => [getCarrierManagerCarriers(state), getMultipleChoiceValues(state)]);
    const [offset, setOffset] = useState(0);
    const [isNextPageEmpty, setIsNextPageEmpty] = useState(true);
    const dispatch = useDispatch();
    const sortByText = useSortByText();

    const requestCarriers = useCallback(async baseQuery => {
        const filteredQuery = Object.entries(baseQuery).filter(([key, value]) => value !== '');
        const query = Object.fromEntries(filteredQuery);
        const [{data: carriers}, {data: nextPageCarriers}] = await Promise.all([
            dispatch(requestCarrierManagerCarriers({
                ...query,
                limit: PER_PAGE_LIMIT,
                offset: offset * PER_PAGE_LIMIT
            })),
            // FYI: get one item on next page to determine is next page available (20.04.22, Yurii)
            dispatch(requestCarrierManagerCarriers({
                ...query,
                limit: 1,
                offset: (offset + 1) * PER_PAGE_LIMIT
            }))
        ]);

        setIsNextPageEmpty(!nextPageCarriers.length);
        dispatch(receiveCarrierManagerCarriers(carriers));
    }, [dispatch, offset]);

    const applyFilter = useCallback(query => {
        setOffset(0);
        requestCarriers(query);
    }, [requestCarriers]);

    useEffect(() => {
        requestCarriers(chosenValues);
    }, [requestCarriers]);

    const loadCoreCarriersOptions = promisifyAsyncFunction(async query => {
        const carriers = await dispatch(requestCoreCarriers(query));

        return carriers.map(({issuer_name: issuerName}) => ({label: issuerName, value: issuerName}));
    });

    const onCreateCarrier = () => navigate(ROUTES.createCarrier);

    const onDeleteCarrier = id => {
        const onDelete = async () => {
            closePopup();
            const {isSuccess} = await dispatch(requestCarrierManagerCarrierDeleting(id));

            if (!isSuccess) {
                return false;
            }

            requestCarriers(chosenValues);
        };

        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button type={BUTTON_TYPES.destructive} onClick={onDelete}>Delete</Button>
            </React.Fragment>
        );
        const popupContent = <Text className='carrier-manager-deletion-popup'>This item will be deleted permanently. You will not be able to undo this action.</Text>;

        const popupProps = {title: 'Are you sure you want to delete this carrier?', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const getTableColumns = () => {
        const getManageBar = ({value: id}) => {
            const onEdit = () => navigate(`${ROUTES.carrierManager}/${id}`);
            const onDelete = () => onDeleteCarrier(id);

            return (
                <React.Fragment>
                    <Icon className='carriers-table-icon' type={ICON_TYPES.edit} onClick={onEdit}/>
                    <Icon className='carriers-table-icon' type={ICON_TYPES.delete} onClick={onDelete}/>
                </React.Fragment>
            );
        };

        return [
            {Header: 'State', accessor: 'state', width: 100},
            {Header: 'Carrier Name', accessor: 'issuer_name', sortType: sortByText},
            {Header: 'Issuer ID', accessor: 'hios_id'},
            {Header: 'Group Name', accessor: 'group_affiliation', sortType: sortByText},
            {Header: 'Change Healthcare ID', accessor: 'pokitdoc_id', sortType: sortByText},
            {Header: 'NPI', accessor: 'npi'},
            {Header: 'Short Name', accessor: 'short_name', sortType: sortByText},
            {Header: 'Manage', accessor: 'id', Cell: getManageBar}
        ];
    };

    const carriersFilteringChoices = [
        {name: 'All', type: 'all'},
        {name: 'State', type: 'state', options: STATES_OPTIONS},
        {name: 'Carrier Name', type: 'issuer_name', loadOptions: loadCoreCarriersOptions},
        {name: 'Issuer ID', type: 'hios_ids', inputType: 'number'},
        {name: 'Change Healthcare ID', type: 'pokitdoc_id'},
        {name: 'NPI', type: 'npi', inputType: 'number'}
    ];
    const tableProps = {
        data: carriers,
        tableConfig: {
            autoResetSortBy: false
        },
        isFilterable: false,
        columns: getTableColumns()
    };

    return (
        <div className='carrier-manager'>
            <Heading className='mb-10' type={HEADING_TYPES['1']}>Carrier Manager</Heading>

            <div className='carriers-table-header'>
                <Row bottom='xs' columnGap='xs'>
                    <Column xs>
                        <Heading className='carriers-table-header__title' type={HEADING_TYPES['4']}>Search</Heading>
                    </Column>
                    <Column constant>
                        <Button onClick={onCreateCarrier} className='carriers-table-header__button'>Add New Carrier</Button>
                    </Column>
                </Row>

                <ContentSection className='carriers-table-header__content-section'>
                    <MultipleChoice choices={carriersFilteringChoices} onChange={applyFilter}/>
                </ContentSection>
            </div>

            <ContentSection>
                <Table {...tableProps}/>

                <div className='carrier-manager__pagination'>
                    <Button disabled={offset === 0} type={BUTTON_TYPES.tertiary} className='pagination__button' iconLeft={<Icon type={ICON_TYPES.chevronLeft}/>} onClick={() => setOffset(offset => offset - 1)}>
                        Previous
                    </Button>
                    <Button disabled={isNextPageEmpty} type={BUTTON_TYPES.tertiary} className='pagination__button' iconRight={<Icon type={ICON_TYPES.chevronRight}/>} onClick={() => setOffset(offset => offset + 1)}>
                        Next
                    </Button>
                </div>
            </ContentSection>
        </div>
    );
};

CarrierManagerLayout.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {CarrierManagerLayout as TestableCarrierManagerLayout};
export default withPopup(POPUP_ID)(CarrierManagerLayout);
