import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, Outlet, useNavigate} from 'react-router-dom';
import {
    getActiveCompany,
    getTimelineExistenceStatus,
    getRoutesUnavailabilityMap,
    getProfileInfo,
    getServerErrorStatus
} from '../../selectors/general';
import {equal, getRouteMatchPath, negate, parseQuery} from '../../utils';
import {ROUTES, ZENDESK_HELP_CENTER_URL, USER_ROLES} from '../../constants';

const HELPCENTER_REDIRECTION_URL = `${ROUTES.externalRedirection}?redirect_to=${ZENDESK_HELP_CENTER_URL}`;
const ZENDESK_LINKS = {
    zendesk: 'zendesk',
    helpCenter: 'help-center',
    helpCenterComEnv: 'healthjoyhelp1712057866'
};

const withRedirectionStrategy = () => {
    const WithRedirectionStrategy = props => {
        const [isReady, setIsReady] = useState(false);
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const activeCompany = useSelector(getActiveCompany);
        const isTimelineExisted = useSelector(getTimelineExistenceStatus);
        const routesUnavailabilityMap = useSelector(getRoutesUnavailabilityMap);
        const serverErrorStatus = useSelector(getServerErrorStatus);
        const {role} = useSelector(getProfileInfo);
        const location = useLocation();
        const {pathname, search} = location || {};
        const currentUrl = `${pathname}${search}`;
        const {return_to: returnTo} = parseQuery(search);
        const isZendesk = Object.values(ZENDESK_LINKS).some(link => returnTo?.includes(link));

        const routes = useMemo(() => Object.keys(routesUnavailabilityMap).map(route => ({path: route})), [routesUnavailabilityMap]);
        const path = getRouteMatchPath(routes, location);

        const getReserveRoute = () => {
            const {isUnavailable, reserveRoute} = routesUnavailabilityMap[path] || {};
            const isAdminPortalPage = path?.startsWith(ROUTES.adminPortalRoot);

            let route = isUnavailable && reserveRoute;
            // FYI: we compare with path due to possibility of using dynamic routes (/:id, /:id?, /:type, etc);
            if (equal(isTimelineExisted, false) && negate(equal(path, ROUTES.timelineGeneration)) && !isAdminPortalPage) {
                route = ROUTES.timelineGeneration;
            }

            return route;
        };

        useEffect(() => {
            const reserveRoute = getReserveRoute();

            if (serverErrorStatus) {
                return navigate(`${ROUTES.serverError}/${serverErrorStatus}`);
            }

            // FYI: We need this redirect for users who have "Help Center-Only Viewer" role,
            // and will not be able to access any other part of HealthJoy’s client dashboard. (Seva 21.08.24)
            if (equal(role, USER_ROLES.zendesk) && !equal(currentUrl, HELPCENTER_REDIRECTION_URL)) {
                return navigate(HELPCENTER_REDIRECTION_URL);
            }

            if (returnTo && isZendesk) {
                return navigate(`${ROUTES.externalRedirection}?redirect_to=${returnTo}`);
            }

            if (reserveRoute) {
                return navigate(reserveRoute);
            }

            setIsReady(true);
        }, [dispatch, returnTo, isZendesk, path, activeCompany, isTimelineExisted, routesUnavailabilityMap, role, serverErrorStatus]);

        const componentProps = {
            ...props,
            redirectTo: navigate,
            activeCompany,
            isTimelineExisted,
            routesUnavailabilityMap
        };

        return isReady && <Outlet {...componentProps}/>;
    };

    return WithRedirectionStrategy;
};

export {withRedirectionStrategy as testableWithRedirectionStrategy};
export default withRedirectionStrategy;
