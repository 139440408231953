import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import CustomizablePopup from '../../Components/shared/CustomizablePopup';
import withPopup from '../withPopup';
import withBroadcastOnboarding from '../withBroadcastOnboarding';
import {goPrevWizardStep, goNextWizardStep} from '../../actions/shared';
import {requestBroadcast, requestBroadcastCreating, requestBroadcastUpdating, requestBroadcastTesting} from '../../actions/broadcaster';
import {getBroadcast} from '../../selectors/broadcaster';
import {ROUTES} from '../../constants';
import {compose} from '../../utils';

const POPUP_ID = 'broadcastStep';

const withBroadcastStepCommon = Component => {
    const WithBroadcastStepCommon = props => {
        const dispatch = useDispatch();
        const {id} = useParams();
        const navigate = useNavigate();
        const broadcast = useSelector(getBroadcast);

        const {setBroadcasterOnboarded, activeStep, stepsCount, openPopup, closePopup} = props;

        const onOpenTestingPopup = email => {
            const content = `A test was sent successfully for user ${email}.`;
            const actionBar = <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Ok</Button>;

            const popupProps = {title: 'Success', content, actionBar};

            openPopup({type: POPUP_TYPES.simple, children: <CustomizablePopup {...popupProps}/>});
        };

        const test = async values => {
            setBroadcasterOnboarded();
            const {isSuccess, submissionErrors} = await dispatch(requestBroadcastTesting(values));

            if (!isSuccess) {
                return submissionErrors;
            }

            onOpenTestingPopup(values.email);
        };

        const submit = async (values, pathTo) => {
            const {isSuccess, submissionErrors} = broadcast.id
                ? await dispatch(requestBroadcastUpdating(values, broadcast.id))
                : await dispatch(requestBroadcastCreating(values));

            if (!isSuccess) {
                return submissionErrors;
            }

            if (pathTo) {
                navigate(`${ROUTES.broadcasterWelcome}`);
            }

            if (!pathTo && activeStep < stepsCount - 1) {
                dispatch(goNextWizardStep());
            }
        };

        useEffect(() => {
            const broadcastId = id || broadcast.id;

            if (broadcastId) {
                dispatch(requestBroadcast(broadcastId));
            }
        }, []);

        const componentProps = {
            ...props,
            broadcast,
            submit: ({is_test: isTest, ...values}, pathTo) => isTest ? test(values) : submit(values, pathTo),
            onGoBack: () => dispatch(goPrevWizardStep())
        };

        return <Component {...componentProps}/>;
    };

    WithBroadcastStepCommon.propTypes = {
        form: PropTypes.string.isRequired,
        activeStep: PropTypes.number.isRequired,
        stepsCount: PropTypes.number.isRequired,
        openPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired,
        isBroadcasterOnboarded: PropTypes.bool,
        setBroadcasterOnboarded: PropTypes.func.isRequired,
        requestBroadcasterOnboardedSetting: PropTypes.func.isRequired
    };

    return WithBroadcastStepCommon;
};

export default compose(
    withPopup(POPUP_ID),
    withBroadcastOnboarding,
    withBroadcastStepCommon
);
