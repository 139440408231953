import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import PerformanceReport from '../../Components/PdfTemplate/PerformanceReport';
import MskReport from '../../Components/PdfTemplate/MskReport';
import Loader from '../../Containers/shared/Loader';
import {getAuthorizationStatus} from '../../selectors/authorization';
import {authorizeByAppSecret} from '../../actions/authorization';
import {setPdfTemplateSubject} from '../../actions/pdfTemplate';
import {PDF_TEMPLATE_TYPES} from '../../constants';
import {parseQuery} from '../../utils';
import './index.scss';

const TEMPLATE_TYPE_MAP = {
    [PDF_TEMPLATE_TYPES.performanceReport]: PerformanceReport,
    [PDF_TEMPLATE_TYPES.mskReport]: MskReport
};

const PdfTemplate = () => {
    const isAuthorized = useSelector(getAuthorizationStatus);
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const {templateType} = params || {};
    const TemplateComponent = TEMPLATE_TYPE_MAP[templateType];

    useEffect(() => {
        const {companyAlias, dataPath} = params || {};
        const {search} = location || {};
        const {app_id: appId, app_secret: appSecret} = parseQuery(search);

        dispatch(authorizeByAppSecret(appId, appSecret));
        dispatch(setPdfTemplateSubject(companyAlias, dataPath));
    }, [dispatch, location, params]);

    return (
        <div className='pdf-template'>
            <Loader/>

            {isAuthorized && <TemplateComponent/>}
        </div>
    );
};

export {PdfTemplate as TestablePdfTemplate};
export default PdfTemplate;
