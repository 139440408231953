import {
    ADMIN_PORTAL_ROUTES,
    COMPANY_STAGES,
    LAUNCHED_TIMELINE_AVAILABILITY_PERIOD,
    ROUTES,
    STEP_ALIASES,
    TIMELINE_STEP_STATUSES,
    USER_TYPES,
    WORKSPACES
} from '../constants';
import {equal, getDifferenceInCalendarDays, getEqual, getItemKeyValue, negateFunc} from '../utils';

export const getWorkspace = state => state.general.workspace;

export const getIsAdminPortal = state => equal(getWorkspace(state), WORKSPACES.adminPortal);

export const getStepAlias = state => state.general.stepAlias;

export const getProfileInfo = state => state.general.profileInfo;

export const getCompaniesMap = state => state.general.companies.companiesMap;

export const getActiveCompany = state => state.general.companies.activeCompany;

export const getRedirectToUrl = state => state.general.redirectToUrl;

export const getServerErrorStatus = state => state.general.serverErrorStatus;

export const getProfilePermissions = state => getProfileInfo(state).permissions;

export const getActiveCompanyName = state => getActiveCompany(state).name;

export const getActiveCompanyStageCriteria = (state, stage) => {
    const {activity_stage: activityStage} = getActiveCompany(state);

    return equal(activityStage, stage);
};

export const getActiveCompanyPlanPeriods = state => getActiveCompany(state).all_plan_periods || [];

export const getActiveCompanyPlanPeriodById = (state, id) => getActiveCompanyPlanPeriods(state).find(getEqual(parseInt(id, 10), 'id')) || {};

export const getPlanPeriodTypeCriteria = (state, periodId, type) => {
    const planPeriods = getActiveCompanyPlanPeriods(state);
    const planPeriodType = planPeriods.find(getEqual(parseInt(periodId, 10), 'id'))?.status;

    return equal(planPeriodType, type);
};

export const getIsSuperAdmin = state => getProfileInfo(state).isSuperAdmin;

export const getTimeline = state => state.general.timeline;

export const getTimelineExistenceStatus = state => getTimeline(state).isExisted;

export const getTimelineSteps = state => getTimeline(state).steps;

export const getTimelineOwner = state => getTimeline(state).owner;

export const getTimelineImplementationManager = state => getTimeline(state).implementation_manager;

export const getTimelineImplementationSpecialists = state => getTimeline(state).implementation_specialists;

export const getTimelineId = state => getTimeline(state).id;

export const getProcessedTimelineStep = state => {
    const steps = getTimelineSteps(state);
    const stepAlias = getStepAlias(state);

    return steps.find(getEqual(stepAlias, 'url'));
};

export const getProcessedTimelineSubStep = state => {
    const {children: subSteps} = getProcessedTimelineStep(state) || {children: []};
    const subStepAlias = window.location.pathname;

    return subSteps.find(getEqual(subStepAlias, 'url'));
};

export const getPriorityTimelineStep = state => {
    const steps = getTimelineSteps(state);

    return steps.find(negateFunc(getEqual(TIMELINE_STEP_STATUSES.completed, 'status'))) || {};
};

export const getOverviewCompletionStatus = state => {
    const steps = getTimelineSteps(state);
    const benefitsOverviewStep = steps.find(getEqual(STEP_ALIASES.benefitsOverview, 'url'));
    const renewalOverviewStep = steps.find(getEqual(STEP_ALIASES.renewalOverview, 'url'));
    const {status} = benefitsOverviewStep || renewalOverviewStep || {};

    return equal(status, TIMELINE_STEP_STATUSES.completed);
};

export const getIsLaunchDatePassed = (state, daysCount = 1) => {
    const {launch_date: launchDate} = getTimeline(state);
    const today = new Date();

    return getDifferenceInCalendarDays(today, launchDate) >= daysCount;
};

export const getPassLength = state => state.authorization.passLength;

export const getMarketingPages = state => state.general.marketingPages;

export const getIsBroadcasterOnboarded = state => {
    const {is_broadcaster_onboarded: isBroadcasterOnboarded} = getProfileInfo(state);

    return isBroadcasterOnboarded;
};

export const getIsBroadcasterApprovalProcess = state => {
    const {is_broadcaster_approval_process_enabled: isBroadcasterApprovalProcess} = getProfileInfo(state);

    return isBroadcasterApprovalProcess;
};

export const getRoutesUnavailabilityMap = state => {
    const isSuperAdmin = getIsSuperAdmin(state);
    const {user_type: profileType} = getProfileInfo(state);
    const profilePermissions = getProfilePermissions(state);
    const isLaunchDatePassed = getIsLaunchDatePassed(state);
    const isTimelineUnavailable = getIsLaunchDatePassed(state, LAUNCHED_TIMELINE_AVAILABILITY_PERIOD);
    const isCompanyLaunching = getActiveCompanyStageCriteria(state, COMPANY_STAGES.launch);
    const isBookOfBusinessReportingUnavailable = !isSuperAdmin && ![USER_TYPES.broker, USER_TYPES.tpa].some(getEqual(profileType));
    const isReportingUnavailable = isCompanyLaunching && !isLaunchDatePassed;
    const isBroadcasterUnavailable = isCompanyLaunching && !isLaunchDatePassed;

    const reportingInitialRoute = isBookOfBusinessReportingUnavailable ? ROUTES.benefitsUsageReporting : ROUTES.bookOfBusinessReporting;

    const UNAVAILABILITY_MAP = {
        [ROUTES.root]: [{isUnavailable: true, isRedirectionForced: true, reserveRoute: isLaunchDatePassed ? reportingInitialRoute : ROUTES.timeline}],
        [ROUTES.adminPortalRoot]: [{isUnavailable: true, isRedirectionForced: true, reserveRoute: isSuperAdmin ? ROUTES.planManager : ROUTES.root}],
        [ROUTES.timeline]: [{isUnavailable: !profilePermissions.timeline_page_view || isTimelineUnavailable, reserveRoute: reportingInitialRoute}],
        [ROUTES.timelineGeneration]: [{isUnavailable: !profilePermissions.timeline_create, isHidden: true, reserveRoute: ROUTES.pageNotFound}],
        [ROUTES.contactInfo]: [{isUnavailable: !profilePermissions.contact_edit && !profilePermissions.contact_delete, reserveRoute: ROUTES.pageNotFound}],
        [ROUTES.companyInfo]: [{isUnavailable: !profilePermissions.company_info_page_view, isHidden: true, reserveRoute: ROUTES.pageNotFound}],
        [`${ROUTES.benefits}/:planPeriodId/core_benefits`]: [{isUnavailable: !profilePermissions.core_overview_page_view, isHidden: true, reserveRoute: ROUTES.pageNotFound}],
        [`${ROUTES.benefits}/:planPeriodId/ancillary_benefits`]: [{isUnavailable: !profilePermissions.ancillary_overview_page_view, isHidden: true, reserveRoute: ROUTES.pageNotFound}],
        [`${ROUTES.benefits}/:planPeriodId/preview`]: [{isUnavailable: !profilePermissions.benefits_preview_page_view, isHidden: true, reserveRoute: ROUTES.pageNotFound}],
        [`${ROUTES.eligibility}/:planPeriodId/export`]: [{isUnavailable: !profilePermissions.export_eligibility_files_page_view, isHidden: true, reserveRoute: ROUTES.pageNotFound}],
        [ROUTES.reporting]: [
            {isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline},
            {isUnavailable: true, isRedirectionForced: true, reserveRoute: reportingInitialRoute}
        ],
        [ROUTES.bookOfBusinessReporting]: [
            {isUnavailable: isBookOfBusinessReportingUnavailable, isHidden: true, reserveRoute: ROUTES.pageNotFound},
            {isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}
        ],
        [ROUTES.costContainmentReporting]: [{isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}],
        [`${ROUTES.benefitsUsageReporting}/:type?`]: [{isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}],
        [ROUTES.completeRoiReporting]: [{isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}],
        [ROUTES.memberActivationReporting]: [
            {isUnavailable: !profilePermissions.reporting_member_activation_page_view, isHidden: true, reserveRoute: ROUTES.root},
            {isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}
        ],
        [ROUTES.reportDownloadsReporting]: [{isUnavailable: isReportingUnavailable, reserveRoute: ROUTES.timeline}],
        [`${ROUTES.educationAndMarketing}/:id`]: [{isUnavailable: !profilePermissions.education_and_marketing_menu_view, isHidden: true, reserveRoute: ROUTES.root}],
        ...[ROUTES.broadcasterWelcome, `${ROUTES.broadcastEditor}/:id?`, `${ROUTES.broadcastDetails}/:id`].reduce((acc, route) => {
            const items = [
                {isUnavailable: !profilePermissions.broadcaster_menu_view, isHidden: true, reserveRoute: ROUTES.pageNotFound},
                {isUnavailable: isBroadcasterUnavailable, reserveRoute: ROUTES.pageNotFound}
            ];

            return {...acc, [route]: items};
        }, {}),
        ...ADMIN_PORTAL_ROUTES.reduce((acc, route) => ({...acc, [route]: [{isUnavailable: !isSuperAdmin, reserveRoute: ROUTES.root}]}), {})
    };

    return Object.entries(UNAVAILABILITY_MAP).reduce((acc, [route, unavailabilityItems]) => {
        return {...acc, [route]: unavailabilityItems.find(getItemKeyValue('isUnavailable')) || {}};
    }, {});
};

export const getStepAliasesByRoutes = state => {
    const isCompanyRenewing = getActiveCompanyStageCriteria(state, COMPANY_STAGES.renewal);

    const coreBenefitsStepAlias = isCompanyRenewing ? STEP_ALIASES.renewalOverview : STEP_ALIASES.benefitsOverview;
    const ancillaryBenefitsStepAlias = isCompanyRenewing ? STEP_ALIASES.renewalOverview : STEP_ALIASES.benefitsOverview;
    const eligibilityOverviewStepAlias = isCompanyRenewing ? STEP_ALIASES.renewalOverview : STEP_ALIASES.benefitsOverview;

    return {
        [ROUTES.companyInfo]: STEP_ALIASES.benefitsOverview,
        [ROUTES.contactInfo]: STEP_ALIASES.benefitsOverview,
        [ROUTES.contacts]: STEP_ALIASES.benefitsOverview,
        [`${ROUTES.benefits}/:planPeriodId/core_benefits`]: coreBenefitsStepAlias,
        [`${ROUTES.benefits}/:planPeriodId/core_benefits_details`]: STEP_ALIASES.gatheringBenefits,
        [`${ROUTES.benefits}/:planPeriodId/ancillary_benefits`]: ancillaryBenefitsStepAlias,
        [`${ROUTES.benefits}/:planPeriodId/ancillary_benefits_details`]: STEP_ALIASES.gatheringBenefits,
        [`${ROUTES.eligibility}/:planPeriodId/implementation`]: eligibilityOverviewStepAlias,
        [`${ROUTES.eligibility}/:planPeriodId/details`]: STEP_ALIASES.gatheringEligibility,
        [ROUTES.benefitsOverview]: STEP_ALIASES.gatheringBenefits,
        [ROUTES.openEnrollment]: STEP_ALIASES.renewalOverview
    };
};
