import {create, CancelToken} from 'apisauce';
import store from './store';
import {setServerErrorStatus} from './actions/general';
import {getPassLength, getIsSuperAdmin} from './selectors/general';
import {finishLoading, startLoading} from './actions/shared';
import {handleTokenRefresh, signOut, updateLogoutParams} from './actions/authorization';
import {getAccessToken, getAuthorizationStatus, getRefreshToken} from './selectors/authorization';
import apiServices from './apiServices';
import {equal, getNormalizedErrorMessages, getErrorlessData, stringifyQueryParams} from './utils';
import {HTTP_CODE_UNAUTHORIZED, SERVER_ERROR, CANCEL_ERROR} from './constants';

const api = create({
    baseURL: '/api',
    headers: {'Content-Type': 'application/json', Pragma: 'no-cache'},
    paramsSerializer: params => stringifyQueryParams(params)
});

const normalizeResponse = response => {
    const {ok, data} = response;

    response.isSuccess = ok;
    response.data = data || {};
};

const normalizeErrorMessages = response => {
    const {isSuccess, data} = response;

    if (isSuccess || !data.messages) {
        return false;
    }

    const normalizedMessages = getNormalizedErrorMessages(data.messages);

    response.data = {...data, messages: normalizedMessages};
};

const getBearerAccessToken = () => {
    const accessToken = getAccessToken(store.getState());

    return accessToken ? `Bearer ${accessToken}` : undefined;
};

// FYI: FileUploader displays not uploaded files too (error instances under the hood),
// that's why we need to store it into form and clear it before we send data to BE. (01.07.2021, Oleh)
const clearErrorData = request => {
    request.data = getErrorlessData(request.data);
};

const addApiVersion = request => {
    const {url, version, isThirdParty} = request;
    const versionPath = `/v${version ?? 1}`;

    if (isThirdParty || url.startsWith(versionPath)) {
        return false;
    }

    request.url = `${versionPath}${url}`;
};

const addAuthorizationHeader = request => {
    const bearerAccessToken = getBearerAccessToken();

    if (!bearerAccessToken || request.noRefresh) {
        return false;
    }

    request.headers = {...request.headers, Authorization: bearerAccessToken};
};

const handleCancelableRequest = (() => {
    const sources = {};

    return request => {
        const {url, isCancelable} = request;

        if (!isCancelable) {
            return false;
        }

        if (sources[url]) {
            sources[url].cancel();
        }

        sources[url] = CancelToken.source();
        request.cancelToken = sources[url].token;
    };
})();

const ensureTokenFreshness = async response => {
    const {config, status, problem} = response;
    const isCancelledRequest = equal(CANCEL_ERROR, problem);

    if (isCancelledRequest || config.noRefresh) {
        return false;
    }

    const state = store.getState();
    const isAuthorized = getAuthorizationStatus(state);
    const isAuthorizationError = equal(status, HTTP_CODE_UNAUTHORIZED);
    if (!isAuthorized || !isAuthorizationError) {
        return false;
    }

    if (config.fresh) {
        return store.dispatch(signOut());
    }

    const bearerAccessToken = getBearerAccessToken();
    if (equal(config.headers.Authorization, bearerAccessToken)) {
        const isSuperAdmin = getIsSuperAdmin(state);
        const token = getRefreshToken(state);
        const {data: tokenData, isSuccess: isRefreshSuccess} = await apiServices.refreshToken({token});

        if (!isRefreshSuccess) {
            return store.dispatch(signOut());
        }

        store.dispatch(handleTokenRefresh(tokenData));

        if (equal(isSuperAdmin, false)) {
            const {data: newData} = await apiServices.getUserAuthData();
            const {dashboard_password_min_length: newPassLength} = newData;

            const passLength = getPassLength(state);

            const passLengthBecameGt = newPassLength > passLength;
            if (passLengthBecameGt) {
                store.dispatch(signOut());
                return false;
            }
            store.dispatch(updateLogoutParams(passLength));
        }
    }

    const {method, baseURL, url: fullUrl, ...restConfig} = config;
    const url = fullUrl.replace(new RegExp(`^${baseURL}`), '');

    const newResponse = await api[method](url, null, {...restConfig, fresh: true});

    Object.assign(response, newResponse);
};

const handleServerError = ({status, problem}) => {
    const isServerError = equal(SERVER_ERROR, problem);

    store.dispatch(setServerErrorStatus(isServerError ? status : null));
};

const showLoader = ({isLoader = true}) => store.dispatch(startLoading(isLoader));
const hideLoader = () => store.dispatch(finishLoading());

api.addRequestTransform(showLoader);
api.addRequestTransform(addAuthorizationHeader);
api.addRequestTransform(addApiVersion);
api.addRequestTransform(handleCancelableRequest);
api.addRequestTransform(clearErrorData);

api.addAsyncResponseTransform(ensureTokenFreshness);
api.addResponseTransform(normalizeResponse);
api.addResponseTransform(normalizeErrorMessages);
api.addResponseTransform(hideLoader);

api.addMonitor(handleServerError);

export default api;
