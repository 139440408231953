import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {useNavigate, useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Select from '@frontend/ui-kit/Components/Select';
import Text from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import Separator from '@frontend/ui-kit/Components/Separator';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import {getDraftFromHtml, getHtmlFromDraft} from '../../shared/WysiwygEditor';
import FileUploader from '../../shared/FileUploader';
import CongratulationPopup from '../../shared/CongratulationPopup';
import {Field, Form} from '../../shared/FormComponents';
import StickyActionSection from '../../shared/StickyActionSection';
import doctorIcon from '../../../static/images/doctor.svg';
import ColorPicker from '../ColorPicker';
import CustomCardConfig from './CustomCardConfig';
import PrefilledFindCareActionFlow from './PrefilledFindCareActionFlow';
import CardPreview from './CardPreview';
import CustomPagePreviewPopup from './CustomPagePreviewPopup';
import CommunicationChannelSection from './CommunicationChannelSection';
import withPopup from '../../../HOC/withPopup';
import {requestCompanies, requestSpecialties, requestProcedures} from '../../../actions/general';
import {
    requestRecommendationsManagerDetailsConfig,
    requestRecommendationsCardDraftCreating,
    requestRecommendationsCardDraftUpdating,
    requestRecommendationsCard,
    requestRecommendationsCardPublishing,
    requestRecommendationsCardUpdatePublishing,
    requestRecommendationsCardUnPublishing
} from '../../../actions/adminPortal';
import {FORMS, RECOMMENDATION_CARD_ACTIONS, RECOMMENDATION_ACTION_FLOWS, ROUTES, PREFILLED_FIND_CARE_ACTION_FLOW_TYPES} from '../../../constants';
import {CARD_ACTION_OPTIONS} from '../../../options';
import {
    equal,
    formatDate,
    getFromObjSafe,
    isEmpty,
    normalizePositiveNumber,
    promisifyAsyncFunction,
    validateCampaignTriggerEvent
} from '../../../utils';
import './index.scss';

const POPUP_ID = 'recommendationCardPopup';
const ACCEPTABLE_IMAGE_FORMATS = ['.jpg', '.jpeg', '.png'];
const MAX_BACKGROUND_IMAGE_SIZE = 2;

const getInitialValues = values => ({
    ...values,
    ...(getFromObjSafe('custom_page_config.body', values) && {
        custom_page_config: {
            ...values.custom_page_config,
            body: getDraftFromHtml(values.custom_page_config.body)
        }
    })
});

const validate = values => {
    return {
        custom_email_campaign: {
            trigger_event: values.custom_email_campaign?.is_enabled ? validateCampaignTriggerEvent(values.custom_email_campaign.trigger_event) : undefined
        }
    };
};

const RecommendationCardForm = ({openPopup, closePopup}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const [formConfig, setFormConfig] = useState({});
    const [cardId, setCardId] = useState(useParams().id);
    const isEditMode = !!cardId;

    const redirectToRecommendations = useCallback(() => navigate(ROUTES.recommendationsManager), []);
    const redirectToRecommendationCard = useCallback(() => navigate(`${ROUTES.recommendationsManager}/${cardId}`), [cardId]);

    const onShowCustomPagePreviewPopup = values => openPopup({type: POPUP_TYPES.simple, children: <CustomPagePreviewPopup values={values}/>});

    const openCongratulationPopup = ({form}) => {
        const onStayOnPage = () => {
            redirectToRecommendationCard();
            form.restart();
            closePopup();
        };

        const actionBar = (
            <React.Fragment>
                <Button data-testid='back-to-list' className='mr-7' type={BUTTON_TYPES.secondary} onClick={redirectToRecommendations}>Go to Manage Cards</Button>
                <Button data-testid='stay' type={BUTTON_TYPES.primary} onClick={onStayOnPage}>OKAY</Button>
            </React.Fragment>
        );

        const icon = <img src={doctorIcon} alt='doctor'/>;

        const children = (
            <CongratulationPopup actionBar={actionBar} icon={icon}>
                <Text>You've successfully saved a New Card</Text>
            </CongratulationPopup>
        );

        return openPopup({type: POPUP_TYPES.fullscreen, children});
    };

    const openRepublishPopup = handleSubmit => {
        const onRepublish = () => {
            handleSubmit();
            closePopup();
        };

        const actionBar = (
            <React.Fragment>
                <Button className='publish-popup__cancel-button mr-4' type={BUTTON_TYPES.secondary} onClick={closePopup}>No</Button>
                <Button data-testid='confirm-republish-card' className='publish-popup__cancel-button' type={BUTTON_TYPES.primary} onClick={onRepublish}>Yes</Button>
            </React.Fragment>
        );

        const popupContent = <Text>Please note this card is already live and will be updated for all eligible members. Changing publishing date from the past to the future or turning communication channels on multiple times may result in duplicate notifications.</Text>;

        const popupProps = {title: 'Do you really want to publish the update?', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const openUnpublishPopup = () => {
        const onRepublish = async () => {
            const {isSuccess} = await dispatch(requestRecommendationsCardUnPublishing(cardId));

            if (isSuccess) {
                setInitialValues(values => ({...values, is_published: false}));
                closePopup();
            }
        };

        const actionBar = (
            <React.Fragment>
                <Button className='publish-popup__cancel-button mr-4' type={BUTTON_TYPES.secondary} onClick={closePopup}>No</Button>
                <Button data-testid='confirm-unpublish-card' className='publish-popup__cancel-button' type={BUTTON_TYPES.primary} onClick={onRepublish}>Yes</Button>
            </React.Fragment>
        );

        const popupContent = <Text>Please note this card will be removed for all eligible members and will be saved as draft.</Text>;

        const popupProps = {title: 'Are you sure you want to unpublish the card?', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const onSubmit = useCallback(async ({meta, ...values}) => {
        // eslint-disable-next-line no-nested-ternary
        const saveFunction = isEditMode
            ? (meta.isDraft ? requestRecommendationsCardDraftUpdating : requestRecommendationsCardUpdatePublishing)
            : (meta.isDraft ? requestRecommendationsCardDraftCreating : requestRecommendationsCardPublishing);

        const enhancedValues = {
            ...values,
            ...(getFromObjSafe('custom_page_config.body', values) && {
                custom_page_config: {
                    ...values.custom_page_config,
                    body: getHtmlFromDraft(values.custom_page_config.body)
                }
            }),
            action_flow: {
                value: values.action_flow?.value,
                ...(getFromObjSafe('action_flow.context', values) && {
                    context: {
                        type: values.action_flow?.context?.type,
                        id: values.action_flow?.context?.value?.id
                    }
                })
            },
            default_email_campaign: {
                ...values.default_email_campaign,
                trigger_event: formConfig?.default_email_campaign_trigger_event
            }
        };

        const {data, isSuccess, submissionErrors} = await dispatch(saveFunction(enhancedValues, cardId));

        if (!isSuccess) {
            return submissionErrors?.admin_panel_representation;
        }

        setCardId(data.id);
        setInitialValues({...values, id: data.id, is_published: !meta.isDraft});
    }, [cardId, dispatch, formConfig, isEditMode]);

    const onChangeRestColors = (value, {batch, change}) => {
        batch(() => {
            change('title.color', value);
            change('description.color', value);
            change('category.color', value);
        });
    };

    const onChangeActionFlow = (value, {change}) => {
        if (!equal(value, RECOMMENDATION_ACTION_FLOWS.findCarePrefilled)) {
            change('action_flow.context', null);
        }
    };

    const loadCompanyOptions = promisifyAsyncFunction(async query => {
        const {companies} = await dispatch(requestCompanies(query));
        return companies.map(({name, alias}) => ({label: name, value: alias}));
    });

    const getUpdatedActionFlow = async actionFlow => {
        const {context} = actionFlow;
        const {type, id} = context ?? {};

        if (isEmpty(context) || !id) {
            return actionFlow;
        }

        const {data} = equal(type, PREFILLED_FIND_CARE_ACTION_FLOW_TYPES.specialty)
            ? await dispatch(requestSpecialties({ids: [id], isLoader: true}))
            : await dispatch(requestProcedures({ids: [id], isLoader: true}));
        const [contextValue] = data;

        return {
            ...actionFlow,
            context: {
                type,
                value: contextValue
            }
        };
    };

    useEffect(() => {
        (async () => {
            const {data: formConfigData} = await dispatch(requestRecommendationsManagerDetailsConfig());

            setFormConfig(formConfigData);

            if (isEditMode) {
                const {data} = await dispatch(requestRecommendationsCard({cardId}));
                const {action_flow: actionFlow} = data;
                const updatedActionFlow = await getUpdatedActionFlow(actionFlow);

                setInitialValues(getInitialValues({
                    ...data,
                    action_flow: updatedActionFlow
                }));
            } else {
                setInitialValues({
                    title: {color: formConfigData.text_colors[0]},
                    description: {color: formConfigData.text_colors[0]},
                    category: {color: formConfigData.text_colors[0]},
                    background: {color: formConfigData.background_colors[0]},
                    cta: {is_enabled: false, color: formConfigData.button_colors[0]},
                    label: {is_enabled: false, color: formConfigData.label_text_colors[0], background_color: formConfigData.label_background_colors[0]},
                    language: formConfigData.languages[0].value,
                    biological_sex: formConfigData.biological_sex_options[0].value,
                    hide_card_action: formConfigData.hide_card_actions[0].value,
                    company_targeting: {action: formConfigData.company_targeting_actions[0].value},
                    product_availability: [],
                    insurance_coverage: [],
                    population_health_recommendations: [],
                    show_to_family_members: true,
                    push_notification: {
                        is_enabled: false,
                        start_day_hours_to_receive: formatDate(new Date(new Date().setHours(9, 0, 0)), "yyyy-MM-dd'T'HH:mm"),
                        end_day_hours_to_receive: formatDate(new Date(new Date().setHours(18, 0, 0)), "yyyy-MM-dd'T'HH:mm")
                    },
                    default_email_campaign: {
                        is_enabled: false,
                        start_day_hours_to_receive: formatDate(new Date(new Date().setHours(9, 0, 0)), "yyyy-MM-dd'T'HH:mm"),
                        end_day_hours_to_receive: formatDate(new Date(new Date().setHours(18, 0, 0)), "yyyy-MM-dd'T'HH:mm")
                    },
                    custom_email_campaign: {
                        is_enabled: false,
                        start_day_hours_to_receive: formatDate(new Date(new Date().setHours(9, 0, 0)), "yyyy-MM-dd'T'HH:mm"),
                        end_day_hours_to_receive: formatDate(new Date(new Date().setHours(18, 0, 0)), "yyyy-MM-dd'T'HH:mm")
                    }
                });
            }
        })();
    }, [dispatch, cardId, isEditMode]);

    const getSidebarContent = ({handleSubmit, form, values, dirty}) => {
        const isAlreadyPublished = values.is_published;

        const isPublishButtonDisabled = (() => {
            const applicationFlowRequiredFields = equal(values.card_action, 'application_flow') ? [
                'action_flow.value',
                ...(equal(values.action_flow?.value, RECOMMENDATION_ACTION_FLOWS.findCarePrefilled) ? ['action_flow.context.type', 'action_flow.context.value'] : [])
            ] : [];
            const requiredFields = [
                'card_type', 'placement', 'category.text', 'title.text', 'description.text', 'background.color',
                'title.color', 'cta.color', 'label.color', 'card_action', 'start_date',
                ...(equal(values.card_action, 'custom_page') ? ['custom_page_config.title', 'custom_page_config.body'] : []),
                ...applicationFlowRequiredFields,
                ...(values.custom_email_campaign?.is_enabled ? ['custom_email_campaign.trigger_event'] : [])
            ];
            const areRequiredFieldsNotFilled = requiredFields.some(field => isEmpty(getFromObjSafe(field, values)));

            if (isAlreadyPublished) {
                return !dirty || areRequiredFieldsNotFilled;
            }

            return areRequiredFieldsNotFilled;
        })();

        const onSaveAndPublish = () => {
            form.change('meta.isDraft', false);
            if (isAlreadyPublished) {
                return openRepublishPopup(handleSubmit);
            }

            handleSubmit();
        };

        const onSaveAsDraft = () => {
            form.change('meta.isDraft', true);
            handleSubmit();
        };

        const onUnPublish = async () => {
            openUnpublishPopup();
        };

        return (
            <div className='sidebar__buttons'>
                {isAlreadyPublished && (
                    <div className='sidebar__disclaimer mt-11 mb-11'>
                        <Icon className='sidebar__disclaimer-icon' type={ICON_TYPES.people}/>
                        <Text>Potential audience: <span className='sidebar__disclaimer-audience'>{values.audience}</span></Text>
                    </div>
                )}

                {!isAlreadyPublished && (
                    <div className='sidebar__disclaimer mb-16'>
                        <Icon className='sidebar__disclaimer-icon' type={ICON_TYPES.alertCircleFull}/>
                        <Text>Once the card is published it will appear for all eligible members in the Carousel section of the app.</Text>
                    </div>
                )}

                <div className='sidebar__buttons-group'>
                    <Button data-testid='cancel-button' type={BUTTON_TYPES.secondary} onClick={redirectToRecommendations}>Cancel</Button>
                    {isAlreadyPublished && (
                        <Button data-testid='unpublish-button' type={BUTTON_TYPES.secondary} onClick={onUnPublish}>Unpublish</Button>
                    )}
                    {!isAlreadyPublished && (
                        <Button data-testid='save_draft-button' disabled={!dirty} type={BUTTON_TYPES.secondary} onClick={onSaveAsDraft}>Save Progress</Button>
                    )}
                </div>

                <Button data-testid='save-button'
                    className='sidebar__save-button'
                    disabled={isPublishButtonDisabled}
                    onClick={onSaveAndPublish}
                    type={BUTTON_TYPES.primary}>
                    Save and Publish
                </Button>
            </div>
        );
    };

    const getToggledArray = (array, value) => array.includes(value)
        ? array.filter(element => !equal(element, value))
        : [...array, value];

    const getProductAvailability = (form, values, {vendor, products}) => {
        const onProductChange = value => {
            const nextProducts = getToggledArray(values.product_availability, value);

            form.change('product_availability', nextProducts);
        };

        return (
            <div className='recommendation-card-creation__product-availability'>
                <Text className='mb-8'>{vendor}:</Text>

                {products.map(({value, label}) => (
                    <Checkbox onChange={() => onProductChange(value)} value={values.product_availability?.includes(value)} wrapperClassName='mb-5' caption={label}/>
                ))}
            </div>
        );
    };

    const getInsuranceCoverage = (form, values, {value, label}) => {
        const onInsuranceChange = value => {
            const nextCoverages = getToggledArray(values.insurance_coverage, value);

            form.change('insurance_coverage', nextCoverages);
        };

        return (
            <Checkbox onChange={() => onInsuranceChange(value)} value={values.insurance_coverage?.includes(value)} wrapperClassName='mb-5' caption={label}/>
        );
    };

    const getPopulationHealthRecommendations = (form, values, {value, label}) => {
        const onPopulationHealthRecommendationChange = value => {
            const nextRecommendations = getToggledArray(values.population_health_recommendations, value);

            form.change('population_health_recommendations', nextRecommendations);
        };

        return (
            <Checkbox onChange={() => onPopulationHealthRecommendationChange(value)} value={values.population_health_recommendations?.includes(value)} wrapperClassName='mb-5' caption={label}/>
        );
    };

    return (
        <Form name={FORMS.createRecommendation} initialValues={initialValues} onSubmit={onSubmit} validate={validate} onSubmitSuccess={openCongratulationPopup}>
            {({handleSubmit, values, form, dirty}) => {
                const sidebarContent = getSidebarContent({handleSubmit, form, values, dirty});
                const isCompanyTargetingActionDefault = equal(
                    values?.company_targeting?.action,
                    (formConfig.company_targeting_actions ? formConfig.company_targeting_actions[0].value : '')
                );

                return (
                    <form onSubmit={handleSubmit} className='recommendation-card-creation' noValidate>
                        <Row>
                            <Column sm>
                                <ContentSection className='mb-5'>
                                    <Heading type={HEADING_TYPES['5']} className='mb-12'>Basic Configuration</Heading>

                                    <Field name='card_type'>
                                        {props => <Select {...props} options={formConfig.cards_types} wrapperClassName='mb-12' label='Card Type' placeholder='Select Option...' isRequired/>}
                                    </Field>

                                    <Row>
                                        <Column sm={6}>
                                            <Field name='internal_description'>
                                                {props => (
                                                    <Input {...props} maxLength={250} wrapperClassName='mb-12' label='Internal Description' placeholder='Type Internal Description...' description="Please enter an internal card description that defines the purpose and details of the card. This description won't disappear in the app."/>
                                                )}
                                            </Field>
                                        </Column>
                                        <Column sm={6}>
                                            <Field name='placement'>
                                                {props => <Select {...props} options={formConfig.placements} label='Recommendation Placement' placeholder='Select Priority...' wrapperClassName='mb-12' isRequired/>}
                                            </Field>
                                        </Column>
                                    </Row>
                                </ContentSection>

                                <CommunicationChannelSection formConfig={formConfig}/>

                                <ContentSection className='mb-5'>
                                    <Heading type={HEADING_TYPES['5']} className='mb-12'>Content</Heading>

                                    <Field name='category.text'>
                                        {props => (
                                            <Input {...props} maxLength={25} isRequired wrapperClassName='mb-12' label='Category Field' placeholder='Select Category' description='Please select what category this card falls under.'/>
                                        )}
                                    </Field>

                                    <Field name='title.text'>
                                        {props => (
                                            <Input {...props} maxLength={35} isRequired wrapperClassName='mb-12' label='Card Title' placeholder='Type Card Title...' description='Please enter the title of the card located at the top of the card.'/>
                                        )}
                                    </Field>

                                    <Field name='description.text'>
                                        {props => (
                                            <Input {...props} maxLength={80} isRequired wrapperClassName='mb-12' label='Description' placeholder='Type Description...' description='Please send a brief description of the card located below the card title.'/>
                                        )}
                                    </Field>

                                    <Row middle='xs' className='mb-12'>
                                        <Column sm={6}>
                                            <Field name='cta.is_enabled'>
                                                {props => <Switcher {...props} caption='CTA'/>}
                                            </Field>
                                        </Column>

                                        <Column sm={6}>
                                            <Field name='cta.text'>
                                                {props => (
                                                    <Input {...props} maxLength={25} label='CTA Label'/>
                                                )}
                                            </Field>
                                        </Column>
                                    </Row>

                                    <Row middle='xs'>
                                        <Column sm={6}>
                                            <Field name='label.is_enabled'>
                                                {props => <Switcher {...props} caption='Show Labels for Cards'/>}
                                            </Field>
                                        </Column>

                                        <Column sm={6}>
                                            <Field name='label.text'>
                                                {props => <Select {...props} options={formConfig.card_labels} label='Label'/>}
                                            </Field>
                                        </Column>
                                    </Row>
                                </ContentSection>

                                <ContentSection className='mb-5'>
                                    <Heading type={HEADING_TYPES['5']} className='mb-12'>Design</Heading>

                                    <Text className='mb-5'>Background Color</Text>
                                    <Field name='background.color' className='mb-12'>
                                        {props => <ColorPicker {...props} colors={formConfig.background_colors}/>}
                                    </Field>

                                    <Field name='background.image_url'>
                                        {props => (
                                            <FileUploader {...props}
                                                accept={ACCEPTABLE_IMAGE_FORMATS}
                                                caption={`Maximum size: ${MAX_BACKGROUND_IMAGE_SIZE}MB`}
                                                label='Upload an Image'
                                                maxSize={MAX_BACKGROUND_IMAGE_SIZE}
                                                isImage
                                                isRemovable/>
                                        )}
                                    </Field>

                                    <Text className='mb-5 mt-12'>Title, Description, Category Color</Text>
                                    <Field name='title.color' onChange={onChangeRestColors} className='mb-12'>
                                        {props => <ColorPicker {...props} colors={formConfig.text_colors}/>}
                                    </Field>

                                    <Text className='mb-5 mt-12'>Select Button Color</Text>
                                    <Field name='cta.color' className='mb-12'>
                                        {props => <ColorPicker {...props} colors={formConfig.button_colors}/>}
                                    </Field>

                                    <Text className='mb-5 mt-12'>Label Color</Text>
                                    <Field name='label.color' className='mb-12'>
                                        {props => <ColorPicker {...props} colors={formConfig.label_text_colors}/>}
                                    </Field>

                                    <Text className='mb-5 mt-12'>Label Background</Text>
                                    <Field name='label.background_color' className='mb-12'>
                                        {props => <ColorPicker {...props} disabled={!values?.label?.is_enabled} colors={formConfig.label_background_colors}/>}
                                    </Field>
                                </ContentSection>

                                <ContentSection className='mb-5'>
                                    <Heading type={HEADING_TYPES['5']} className='mb-12'>Resulting Page</Heading>

                                    <Field name='card_action' className='mb-12'>
                                        {props => <Select {...props} isRequired options={CARD_ACTION_OPTIONS} label='Card Action' placeholder='Select Action...'/>}
                                    </Field>

                                    {equal(values.card_action, 'custom_page') && (
                                        <CustomCardConfig/>
                                    )}

                                    <Field name='action_flow.value' onChange={onChangeActionFlow}>
                                        {props => (
                                            <Select {...props}
                                                isRequired={equal(values.card_action, RECOMMENDATION_CARD_ACTIONS.applicationFlow)}
                                                options={formConfig.action_flows}
                                                label='Action Flow'
                                                placeholder='Select Action...'/>
                                        )}
                                    </Field>

                                    {equal(values.action_flow?.value, RECOMMENDATION_ACTION_FLOWS.findCarePrefilled) && (
                                        <PrefilledFindCareActionFlow context={values.action_flow?.context}/>
                                    )}

                                    {equal(values.card_action, 'custom_page') && (
                                        <Button data-testid='show-custom-page-preview' className='mt-12' type={BUTTON_TYPES.secondary} onClick={() => onShowCustomPagePreviewPopup(values)}>
                                            <Icon type={ICON_TYPES.show}/> Show Custom Page Preview
                                        </Button>
                                    )}
                                </ContentSection>

                                <ContentSection className='mb-5'>
                                    <Heading type={HEADING_TYPES['5']} className='mb-12'>Timeline and Expiration</Heading>

                                    <Row middle='xs' className='mb-12'>
                                        <Column sm={6}>
                                            <Field name='start_date'>
                                                {props => (
                                                    <DatePicker {...props}
                                                        minDate={new Date()}
                                                        maxDate={values.expiration_date && new Date(values.expiration_date)}
                                                        label='Start Date'
                                                        placeholder='Choose date...'
                                                        isRequired/>
                                                )}
                                            </Field>
                                        </Column>
                                        <Column sm={6}>
                                            <Field name='expiration_date'>
                                                {props => (
                                                    <DatePicker {...props}
                                                        minDate={values.start_date
                                                            ? Math.max(new Date(), new Date(values.start_date))
                                                            : new Date()}
                                                        label='Expiration Date'
                                                        placeholder='Choose date...'
                                                        isClearable/>
                                                )}
                                            </Field>
                                        </Column>
                                    </Row>

                                    <Row middle='xs'>
                                        <Column sm={6}>
                                            <Field name='hide_card_action'>
                                                {props => <Select {...props} options={formConfig.hide_card_actions} label='Action-Based' placeholder='Select Option...'/>}
                                            </Field>
                                        </Column>
                                    </Row>
                                </ContentSection>

                                <ContentSection className='mb-5'>
                                    <Heading type={HEADING_TYPES['5']} className='mb-12'>Audience Customization</Heading>

                                    <Row middle='xs' className='mb-12'>
                                        <Column sm={6}>
                                            <Field name='min_age' parse={normalizePositiveNumber}>
                                                {props => <Input {...props} label='Age (availability from)'/>}
                                            </Field>
                                        </Column>
                                        <Column sm={6}>
                                            <Field name='max_age' parse={normalizePositiveNumber}>
                                                {props => <Input {...props} label='Age (availability to)'/>}
                                            </Field>
                                        </Column>
                                    </Row>

                                    <Row middle='xs'>
                                        <Column sm={6}>
                                            <Field name='biological_sex'>
                                                {props => <Select {...props} options={formConfig.biological_sex_options} label='Biological Sex' placeholder='Select Gender'/>}
                                            </Field>
                                        </Column>
                                        <Column sm={6}>
                                            <Field name='language'>
                                                {props => <Select {...props} options={formConfig.languages} label='Language' placeholder='Select Language'/>}
                                            </Field>
                                        </Column>
                                    </Row>

                                    <Separator/>

                                    <Row>
                                        <Column sm={6}>
                                            <Field name='insurance_coverage'>
                                                {() => (
                                                    <div>
                                                        <Text className='mb-8'>Only for members with all selected:</Text>
                                                        <Row className='ml-1'>
                                                            <Column className='mr-13' constant>
                                                                {formConfig?.insurance_coverage?.map(insurance => getInsuranceCoverage(form, values, insurance))}
                                                            </Column>
                                                            <Column>
                                                                <Field name='exclude_bind_plans'>{props => <Checkbox {...props} caption='Exclude Bind plans'/>}</Field>
                                                            </Column>
                                                        </Row>
                                                    </div>
                                                )}
                                            </Field>
                                        </Column>
                                        <Column sm={6}>
                                            <Field name='show_to_family_members'>
                                                {props => <Switcher {...props} caption='Show to family members'/>}
                                            </Field>
                                        </Column>
                                    </Row>

                                    <Separator/>

                                    <Text className='mb-8'>Population Health Recommendations:</Text>
                                    <Field name='population_health_recommendations'>
                                        {() => (
                                            <div>
                                                {formConfig?.population_health_traits?.map(recommendation => getPopulationHealthRecommendations(form, values, recommendation))}
                                            </div>
                                        )}
                                    </Field>
                                </ContentSection>

                                <ContentSection className='mb-5'>
                                    <Heading type={HEADING_TYPES['5']} className='mb-12'>Product Availability and App Config filters</Heading>

                                    <Field name='product_availability'>
                                        {() => (
                                            <div className='recommendation-card-creation__product-availability__container'>
                                                {formConfig?.product_availability?.map(product => getProductAvailability(form, values, product))}
                                            </div>
                                        )}
                                    </Field>

                                    <Separator/>

                                    <Field name='company_targeting.action'>
                                        {props => (
                                            <RadioGroup {...props}
                                                options={formConfig.company_targeting_actions}
                                                wrapperClassName='mb-8'
                                                isInline/>
                                        )}
                                    </Field>
                                    <Field name='company_targeting.aliases'>
                                        {props => (
                                            <AsyncAutocomplete {...props}
                                                disabled={isCompanyTargetingActionDefault}
                                                isCreatable={false}
                                                loadOptions={loadCompanyOptions}
                                                label={`Companies ${!isCompanyTargetingActionDefault ? '*' : ''}`}
                                                isMulti/>
                                        )}
                                    </Field>
                                </ContentSection>
                            </Column>

                            <Column constant>
                                <StickyActionSection title='New Recommendation' actionBar={<CardPreview/>}>
                                    {sidebarContent}
                                </StickyActionSection>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

RecommendationCardForm.propTypes = {
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {RecommendationCardForm as TestableRecommendationCardForm};
export default withPopup(POPUP_ID)(RecommendationCardForm);
