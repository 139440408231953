import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import MainWrapper from '../../Components/shared/MainWrapper';
import Header from '../../Containers/shared/Header';
import Loader from '../../Containers/shared/Loader';
import Toast from '../../Components/shared/Toast';
import PageLayout from '../../Containers/shared/PageLayout';
import Sidebar from '../../Containers/shared/Sidebar';
import withRedirectionStrategy from '../withRedirectionStrategy';
import withNavigationControl from '../withNavigationControl';
import withWorkspaceControl from '../withWorkspaceControl';
import {requestAdminPortalBasicData} from '../../actions/general';
import {clearMultipleChoice} from '../../actions/adminPortal';
import {getProfileInfo} from '../../selectors/general';
import {WORKSPACES} from '../../constants';
import {compose} from '../../utils';

const withAdminPortalLayout = () => {
    const EnhancedComponent = withRedirectionStrategy();

    const WithAdminPortalLayout = props => {
        const dispatch = useDispatch();
        const {id: profileInfoId} = useSelector(getProfileInfo);
        const {pathname} = useLocation();
        const isLoaded = !!profileInfoId;
        const sectionPathName = pathname.split('/')[2];

        useEffect(() => {
            dispatch(requestAdminPortalBasicData());

            return () => {
                dispatch(clearMultipleChoice());
            };
        }, [dispatch]);

        useEffect(() => {
            dispatch(clearMultipleChoice());
        }, [sectionPathName, dispatch]);

        return (
            <React.Fragment>
                <Loader/>
                <Toast/>

                {isLoaded && (
                    <React.Fragment>
                        <Header/>

                        <MainWrapper>
                            <Sidebar/>

                            <PageLayout>
                                {/* FYI: key prop helps to remount component after each relocation */}
                                <EnhancedComponent key={pathname} {...props}/>
                            </PageLayout>
                        </MainWrapper>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    WithAdminPortalLayout.propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string
        }),
        profileInfo: PropTypes.shape({
            id: PropTypes.number
        }),
        requestAdminPortalBasicData: PropTypes.func,
        clearMultipleChoice: PropTypes.func
    };

    return WithAdminPortalLayout;
};

export {withAdminPortalLayout as testableWithAdminPortalLayout};
export default compose(
    withNavigationControl,
    withWorkspaceControl({workspace: WORKSPACES.adminPortal}),
    withAdminPortalLayout
);
