import React, {useCallback, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Popup from '@frontend/ui-kit/Components/Popup';
import {closePopup, openPopup} from '../../actions/shared';
import {getPopupState} from '../../selectors/shared';
import {generateUniqueId} from '../../utils';

const withPopup = (popupId, isUniqueIdPostfix = false) => Component => {
    const WithPopup = props => {
        const dispatch = useDispatch();
        const popupState = useSelector(getPopupState);
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        const {pathname} = useLocation();
        const {current: currPopupId} = useRef(isUniqueIdPostfix ? `${popupId}_${generateUniqueId()}` : popupId);
        const onOpenPopup = useCallback(params => dispatch(openPopup({id: currPopupId, ...params})), [dispatch, currPopupId]);
        const onClosePopup = useCallback(() => dispatch(closePopup({id: currPopupId})), [dispatch, currPopupId]);

        const destroy = () => {
            const {isOpened} = popupState[currPopupId] || {};

            return isOpened ? onClosePopup() : false;
        };

        useEffect(() => {
            destroy();

            return () => destroy();
        }, [pathname]);

        const popupProps = {...popupState[currPopupId], onClose: onClosePopup};
        const componentProps = {...props, openPopup: onOpenPopup, closePopup: onClosePopup};

        return (
            <React.Fragment>
                <Popup {...popupProps}/>
                <Component {...componentProps}/>
            </React.Fragment>
        );
    };

    return WithPopup;
};

export {withPopup as testableWithPopup};
export default withPopup;
