import analyticsService from '../analyticsService';
import * as types from '../actions/actionTypes';
import {getActiveCompany, getTimelineExistenceStatus} from '../selectors/general';
import {getAuthorizationStatus, getIsTwoStepRequired} from '../selectors/authorization';
import {getReportingPlanPeriod} from '../selectors/reporting';
import {TRACKED_ROUTES, TRACKED_SELECTIONS, TRACKED_SEARCHES, ANALYTICS_EVENT_NAMES} from '../analyticsService/constants';
import {ROUTES, REPORTING_PLAN_PERIODS, FILE_FORMATS, LOCAL_STORAGE_ITEMS, SESSION_STORAGE_ITEMS} from '../constants';
import {normalizeBoolean, getFileFormat, equal, negate} from '../utils';

const identifyUser = (action, state) => {
    const isAnalyticsIdentified = localStorage.getItem(LOCAL_STORAGE_ITEMS.isAnalyticsIdentified);

    const {
        profileInfo: {id, user_type: userType, general_role: generalRole, role: userRole},
        activeCompany: {alias}
    } = action;
    const activeCompany = getActiveCompany(state);
    const is2faEnabled = getIsTwoStepRequired(state);

    const isIdentified = isAnalyticsIdentified && normalizeBoolean(isAnalyticsIdentified);
    const isCompanySwitched = activeCompany?.alias && !equal(alias, activeCompany?.alias);

    if (isIdentified && !isCompanySwitched) {
        return false;
    }

    const traits = analyticsService.generateBasicTraits({alias, generalRole, userRole, userType, is2faEnabled});

    analyticsService.identify(id, traits);
    if (!isIdentified) {
        analyticsService.track(ANALYTICS_EVENT_NAMES.successfulLoginAttempt);
    }
};

const unauthorizeUser = () => {
    analyticsService.reset();
};

export const trackLocationChange = (() => {
    let previousLocation; // FYI: We can't get previous location from action or history and we need to save it for checking route change (Pasha, 30.12.2020)

    return (action, state) => {
        const {payload: {location: {pathname}}} = action;
        const isAuthorized = getAuthorizationStatus(state);
        const isTrackedRoute = TRACKED_ROUTES.some(route => pathname.startsWith(route));
        const isRootRoute = pathname === ROUTES.root;
        const isUntrackedRoute = !isTrackedRoute && !isRootRoute;
        const isAuthorizedRootRoute = isAuthorized && isRootRoute;
        const isPathnameChanged = negate(equal(pathname, previousLocation));

        const isTimelineExisted = getTimelineExistenceStatus(state) ?? true; // FYI: On the first page render isTimelineExisted value is undefined. So we use the default value equal true to be sure that trackPageLoad will sent in this case (Pasha, 10.02.20)
        const isTimelineGeneration = isAuthorized && !isTimelineExisted;

        if (isUntrackedRoute || isAuthorizedRootRoute || !isPathnameChanged || isTimelineGeneration) { // FYI: We don't need to track root route if user authorized, because it's always unavailable. Also route stay the same when changing hash and while generating timeline (Vlad, 05.07.20)
            return false;
        }

        const isInitialLoad = !sessionStorage.getItem(SESSION_STORAGE_ITEMS.isInitialPageLoaded);
        analyticsService.trackPageLoad(isInitialLoad);

        previousLocation = pathname;
        sessionStorage.setItem(SESSION_STORAGE_ITEMS.isInitialPageLoaded, true);
    };
})();

const trackDownloadPerformanceReport = (action, state) => {
    const {fileDetails} = action;
    const {name, last_modified: lastModified} = fileDetails;
    const fileFormat = getFileFormat(name);
    const planPeriod = getReportingPlanPeriod(state);
    const timeFrame = equal(fileFormat, FILE_FORMATS.csv) ? REPORTING_PLAN_PERIODS.all : planPeriod; // FYI: timeFrame for csv should be always all (Vlad, 07.07.20)

    analyticsService.trackDownload({name, generationDate: lastModified, timeFrame});
};

const trackDownloadActivationReport = action => {
    const {fileDetails: {name}} = action;

    analyticsService.trackDownload({name});
};

const trackDownloadReport = action => {
    const {fileDetails: {name}} = action;

    analyticsService.trackDownload({name});
};

const trackDownloadResourceFile = action => {
    const {fileData} = action;
    const {name} = fileData;

    analyticsService.trackDownload({name});
};

const trackDownloadEligibilityExportFile = action => {
    const {fileData} = action;
    const {name} = fileData;

    analyticsService.trackDownload({name});
};

const trackSetReportingPlanPeriod = ({planPeriod}) => analyticsService.trackToggleSelection(TRACKED_SELECTIONS.planPeriodSelection, planPeriod);

const trackSearchMembers = ({query}) => query && analyticsService.trackSearch(TRACKED_SEARCHES.memberActivationStatusSearch, query);

const trackAuthorizationFailure = () => analyticsService.track(ANALYTICS_EVENT_NAMES.failedLoginAttempt);

const trackNewBroadcast = action => {
    const CUSTOM_TEMPLATE_NAME = 'Custom';
    const {name} = action.broadcastTemplate ?? {};

    return analyticsService.track(ANALYTICS_EVENT_NAMES.newBroadcast, {template_name: name ?? CUSTOM_TEMPLATE_NAME});
};

const trackAnalytics = (action, {getState}) => {
    const state = getState();
    const {type} = action;
    const TRACKED_EVENTS_MAP = {
        [types.RECEIVE_BASIC_DATA]: identifyUser,
        [types.UNAUTHORIZE]: unauthorizeUser,
        [types.SET_REPORTING_PLAN_PERIOD]: trackSetReportingPlanPeriod,
        [types.DOWNLOAD_PERFORMANCE_REPORT]: trackDownloadPerformanceReport,
        [types.DOWNLOAD_ACTIVATION_REPORT]: trackDownloadActivationReport,
        [types.DOWNLOAD_REPORT]: trackDownloadReport,
        [types.REQUEST_SEARCHED_MEMBERS]: trackSearchMembers,
        [types.DOWNLOAD_RESOURCE_FILE]: trackDownloadResourceFile,
        [types.DOWNLOAD_ELIGIBILITY_EXPORT_FILE]: trackDownloadEligibilityExportFile,
        [types.AUTHORIZATION_FAILED]: trackAuthorizationFailure,
        [types.SET_BROADCAST_TEMPLATE]: trackNewBroadcast
        // FYI: Moved to withAuthorizationControl due to eliminating connected-react-router lib (02.13.2025, Pasha)
        // [LOCATION_CHANGE]: trackLocationChange
    };

    const eventTracker = TRACKED_EVENTS_MAP[type];

    if (!eventTracker) {
        return false;
    }

    eventTracker(action, state);
};

const analyticsTracker = store => next => action => {
    trackAnalytics(action, store);

    return next(action);
};

export default analyticsTracker;
