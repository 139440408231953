import {combineReducers} from 'redux';
import general from './general';
import shared, {initialState as sharedInitialState} from './shared';
import sidebar from './sidebar';
import internalMenu from './internalMenu';
import authorization from './authorization';
import home from './home';
import benefits from './benefits';
import contacts from './contacts';
import company from './company';
import eligibility from './eligibility';
import overview from './overview';
import reporting from './reporting';
import educationAndMarketing from './educationAndMarketing';
import broadcaster from './broadcaster';
import pdfTemplate from './pdfTemplate';
import adminPortal from './adminPortal';
import {UNAUTHORIZE} from '../actions/actionTypes';
import {equal} from '../utils';

const appReducer = combineReducers({
    general,
    shared,
    adminPortal,
    sidebar,
    internalMenu,
    authorization,
    home,
    benefits,
    contacts,
    company,
    eligibility,
    overview,
    reporting,
    educationAndMarketing,
    broadcaster,
    pdfTemplate
});

export default (state, action) => {
    // FYI: hack for state refreshing on sign-out.
    // We don't refresh router because of it strange behaviour (it redirects us to previous route)
    // and loader, because of common state for authorized/unauthorized user
    if (equal(action.type, UNAUTHORIZE)) {
        const {router, shared} = state;
        const {loader} = shared;

        state = {router, shared: {...sharedInitialState, loader}}; // eslint-disable-line
    }

    return appReducer(state, action);
};
