import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
// import {actionNavigationControllerMiddleware} from '@frontend/ui-kit/Components/NavigationController';
import analyticsTracker from './middlewares/analyticsTracker';
import reducers from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureStore = initialState => {
    const middlewares = [
        thunk,
        // actionNavigationControllerMiddleware,
        analyticsTracker
    ];

    return createStore(
        reducers,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );
};

export default configureStore();
